<div class="el-centered fade-in">
    <div class="el-stripe" style="margin: auto">
        <div class="el-bounds _narrow">
            <div class="el-centered__main">
                <div class="el-centered__main_aside">
                    <div class="el-section eu-align_center eu-align_left_md">
                        <a href="javascript:void(0)">
                            <div class="emd-logo-custom__image"></div>
                        </a>
                    </div>
                    <div class="eu-display_none eu-display_block_md">
                        <div class="el-chunk">
                            <h1 class="emd-text _size_page _color_primary">Partner Hub</h1>
                        </div>
                        <p class="emd-text _size_section _color_default">
                            <strong>Definição de senha</strong>
                        </p>
                    </div>
                </div>
                <div class="el-centered__main_body">
                    <div class="el-section">
                        <div class="emd-container _highlight_footer _footer_centered">
                            <!--Input da senha-->
                            <div class="emd-container__content">
                                <div class="el-chunk">
                                    <i class="fa fa-user-circle-o"></i> {{ accountUserEmail }}
                                </div>
                                <div class="el-chunk">
                                    <p class="emd-text _color_nonessential _size_small">
                                        Sua senha deverá ter ao menos 8 caracteres com letras
                                        maiúsculas e minusculas (a-z, A-Z) números (0-9) e
                                        caracteres especiais {{ ' (@ $ ! % * ? & # . _ -)'
                                        }}<br /><br />
                                        <strong>A nova senha não pode:</strong><br />
                                        Conter sequencias (123 , abc , 4321 e afins)<br />
                                        Ter sido utilizada nas ultimas 3 alterações de senhas
                                    </p>
                                </div>
                                <div class="emd-form-group">
                                    <div
                                        class="emd-form-group"
                                        [ngClass]="{ _state_error: showInvalidPasswordSchema }"
                                    >
                                        <label class="emd-label"> Senha </label>
                                        <div class="emd-input-group">
                                            <input
                                                id="password"
                                                [type]="showPassword ? 'text' : 'password'"
                                                placeholder="Digite sua senha"
                                                required="required"
                                                minlength="6"
                                                class="emd-input fs-exclude"
                                                [(ngModel)]="accountUserPassword"
                                                (keyup)="
                                                    validateCreateAccountUserAfterPreRegisterPassword(
                                                        $event
                                                    )
                                                "
                                            />
                                            <a
                                                href="javascript:void(0)"
                                                tabindex="-1"
                                                (click)="showPassword = showPassword ? false : true"
                                                class="emd-input-icon__link _align_right emd-link _color_primary"
                                            >
                                                <i *ngIf="!showPassword" class="fa fa-eye"></i>
                                                <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="emd-form-group">
                                        <label class="emd-label">Confirmação de senha </label>
                                        <div class="emd-input-group">
                                            <input
                                                id="confirmPassword"
                                                [type]="showConfirmPassword ? 'text' : 'password'"
                                                placeholder="Digite sua senha"
                                                required="required"
                                                minlength="6"
                                                class="emd-input fs-exclude"
                                                [(ngModel)]="accountUserConfirmPassword"
                                                onpaste="return false"
                                                ondrop="return false"
                                            />
                                            <a
                                                href="javascript:void(0)"
                                                tabindex="-1"
                                                (click)="
                                                    showConfirmPassword = showConfirmPassword
                                                        ? false
                                                        : true
                                                "
                                                class="emd-input-icon__link _align_right emd-link _color_primary"
                                            >
                                                <i
                                                    *ngIf="!showConfirmPassword"
                                                    class="fa fa-eye"
                                                ></i>
                                                <i
                                                    *ngIf="showConfirmPassword"
                                                    class="fa fa-eye-slash"
                                                ></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="emd-text _align_right eu-mb_3">
                                    <button
                                        type="submit"
                                        tabindex="0"
                                        class="emd-btn _color_primary"
                                        [disabled]="
                                            !accountUserPassword ||
                                            accountUserPassword !== accountUserConfirmPassword ||
                                            showInvalidPasswordSchema
                                        "
                                        (click)="firePasswordUpdate()"
                                    >
                                        <span *ngIf="!redefinePasswordLoaderSetVisible"
                                            >Entrar</span
                                        >
                                        <span *ngIf="redefinePasswordLoaderSetVisible">
                                            <div class="emd-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer></footer>
                </div>
            </div>
        </div>
    </div>
</div>
