import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core'
import { NavigationBarComponent } from '../../../features/navigation/navigation-bar/navigation-bar.component'
import { CoreModule } from '../../../legacy/core/core.module'
import { NavigationBarService } from '../../../features/navigation/services/navigation-bar.service'
import { AsyncPipe } from '@angular/common'
import { Subject, takeUntil } from 'rxjs'
import {
    LoggedUser,
    ManageLoggedUser,
} from '../../../legacy/authentication/services/manage-logged-user.service'
import { NavigationEnd, Router } from '@angular/router'

@Component({
    selector: 'app-base-layout',
    standalone: true,
    imports: [NavigationBarComponent, CoreModule, AsyncPipe],
    templateUrl: './base-layout.component.html',
    styleUrl: './base-layout.component.scss',
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
    navBarService = inject(NavigationBarService)
    loggedUserService = inject(ManageLoggedUser)
    router = inject(Router)

    isSimpleNavigation = signal(true)

    private _onDestroy$ = new Subject()

    canShowSideBar = signal<boolean>(true)

    ngOnInit(): void {
        this.defineSideBarVisibility()
        this.observeNavMode()
        this.observeRouting()
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }

    private observeNavMode() {
        this.navBarService.isSimplifiedNavigation
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(isSimpleNav => {
                this.isSimpleNavigation.set(isSimpleNav)
            })
    }

    private observeRouting() {
        this.router.events.pipe(takeUntil(this._onDestroy$)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.defineSideBarVisibility()
            }
        })
    }

    private defineSideBarVisibility() {
        const currentRoute = window.location.hash
        this.canShowSideBar.set(!currentRoute.includes('partnership-owner-setup'))
    }
}
