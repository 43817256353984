<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Adequação de segurança</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="el-chunk">
            <p class="emd-text">
                Para garantir ainda mais segurança para sua conta no Partner Hub, é necessário
                realizarmos uma adequação na autenticação de dois fatores
            </p>
        </div>
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <h1 class="emd-text step-title _color_default">
                <strong>Clique em continuar para realizar o reset</strong>
            </h1>
            <div class="el-chunk">
                <p class="emd-text">
                    Ao prosseguir, <strong>um SMS com o código de verificação Pagar.me</strong> será
                    enviado para seu celular <strong>dentro de alguns segundos</strong>. Ele deve
                    ser usado no processo de reset da autenticação de dois fatores que será
                    apresentado a seguir.
                </p>
            </div>
        </div>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button class="emd-btn _color_primary _type_text" (click)="handleBackStep()">
                Voltar
            </button>
            <button class="emd-btn _color_primary" (click)="handleNextStep()">Continuar</button>
        </div>
    </div>
</div>
