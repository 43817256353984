import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export enum TwoFactorConfigurationSteps {
    STEP_1,
    STEP_2,
    STEP_3,
    STEP_4,
}

@Component({
    selector: 'app-two-factor-configuration-page',
    templateUrl: './two-factor-configuration-page.component.html',
    styleUrls: ['./two-factor-configuration-page.component.scss'],
})
export class TwoFactorConfigurationPageComponent implements OnInit {
    twoFactorSteps = TwoFactorConfigurationSteps
    currentStep = TwoFactorConfigurationSteps.STEP_1
    recoveryCode: string = ''

    @Input() authCode!: string
    @Input() authType!: number
    @Input() accountUserEmail!: string
    @Output() onBackToLogin = new EventEmitter()
    @Output() onFinish2faConfiguration = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    public handleBackStep() {
        if (this.currentStep === TwoFactorConfigurationSteps.STEP_1) {
            this.onBackToLogin.emit()
        } else {
            this.currentStep = this.currentStep - 1
        }
    }

    public handleNextStep(event: any) {
        if (this.currentStep === TwoFactorConfigurationSteps.STEP_4) {
            this.onFinish2faConfiguration.emit()
            return
        }

        if (this.currentStep === TwoFactorConfigurationSteps.STEP_3) {
            this.recoveryCode = event
        }

        this.currentStep = this.currentStep + 1
    }
}
