<div class="emd-container step-container">
    <div id="success-container" class="emd-container__content">
        <div class="el-chunk">
            <label class="emd-label"><strong>Conta Recuperada!</strong></label>
        </div>
        <div class="eu-py_4"><img src="/assets/icons/thumbs-up.svg" /></div>
        <span class="eu-mb_2">
            Para seguir configure novamente o seu segundo fator de autenticação.
        </span>
        <button class="emd-btn _color_primary" (click)="handleSuccessRecoveryAccount()">
            Configurar Autenticação
        </button>
    </div>
</div>
