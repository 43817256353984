import { Component, input } from '@angular/core'
import { SvgIconComponent } from 'angular-svg-icon'

@Component({
    selector: 'app-node-icon',
    standalone: true,
    imports: [SvgIconComponent],
    templateUrl: './node-icon.component.html',
    styleUrl: './node-icon.component.scss',
})
export class NodeIconComponent {
    iconUrl = input.required<string>()
}
