import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TwoFactorConfigurationStep1Component } from './components/2fa/two-factor-configuration-step1/two-factor-configuration-step1.component'
import { TwoFactorConfigurationStep2Component } from './components/2fa/two-factor-configuration-step2/two-factor-configuration-step2.component'
import { TwoFactorConfigurationStep3Component } from './components/2fa/two-factor-configuration-step3/two-factor-configuration-step3.component'
import { TwoFactorConfigurationStep4Component } from './components/2fa/two-factor-configuration-step4/two-factor-configuration-step4.component'
import { TwoFactorConfigurationPageComponent } from './components/2fa/two-factor-configuration-page/two-factor-configuration-page.component'
import { RecoveryAccountSuccessComponent } from './components/2fa/recovery-account-success/recovery-account-success.component'
import { TwoFactorExecuteForceSmsResetComponent } from './components/2fa/two-factor-execute-force-sms-reset/two-factor-execute-force-sms-reset.component'
import { TwoFactorForceSmsResetPageComponent } from './components/2fa/two-factor-force-sms-reset-page/two-factor-force-sms-reset-page.component'
import { TwoFactorForceSmsResetComponent } from './components/2fa/two-factor-force-sms-reset/two-factor-force-sms-reset.component'
import { TwoFactorFaqComponent } from './components/2fa/two-factor-faq/two-factor-faq.component'
import { CodelessAccessRecoveryComponent } from './components/2fa/codeless-access-recovery/codeless-access-recovery.component'
import { RecoveryAccountSuccessModalComponent } from './components/2fa/recovery-account-success-modal/recovery-account-success-modal.component'
import { RecoveryAccountComponent } from './components/2fa/recovery-account/recovery-account.component'
import { LoginProblemsPageComponent } from './components/2fa/login-problems-page/login-problems-page.component'
import { LoginProblemsInfoComponent } from './components/2fa/login-problems-info/login-problems-info.component'
import { TwoFactorLoginComponent } from './components/2fa/two-factor-login/two-factor-login.component'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { CoreModule } from '../core/core.module'
import { FormsModule } from '@angular/forms'
import { SharedModule } from '../shared/shared.module'
import { AuthenticationComponent } from './components/login/authentication.component'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2'
import { AccountUserDefinePasswordComponent } from './components/define-password/account-user-define-password.component'
import { LoggedUser } from './components/logged-user/logged-user.component'

@NgModule({
    declarations: [
        TwoFactorConfigurationStep1Component,
        TwoFactorConfigurationStep2Component,
        TwoFactorConfigurationStep3Component,
        TwoFactorConfigurationStep4Component,
        TwoFactorConfigurationPageComponent,
        TwoFactorLoginComponent,
        LoginProblemsInfoComponent,
        LoginProblemsPageComponent,
        RecoveryAccountComponent,
        RecoveryAccountSuccessModalComponent,
        CodelessAccessRecoveryComponent,
        TwoFactorFaqComponent,
        TwoFactorForceSmsResetComponent,
        TwoFactorForceSmsResetPageComponent,
        TwoFactorExecuteForceSmsResetComponent,
        RecoveryAccountSuccessComponent,
        AuthenticationComponent,
        AccountUserDefinePasswordComponent,
        LoggedUser,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        CoreModule,
        SharedModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    exports: [AuthenticationComponent, AccountUserDefinePasswordComponent],
})
export class AuthenticationModule {}
