import { Component, computed, inject, input, OnDestroy, OnInit, signal } from '@angular/core'
import { SvgIconComponent } from 'angular-svg-icon'
import { ThemeSwitcherService } from '../../../../legacy/core/services/theme-switcher.service'
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'app-theme-switcher',
    standalone: true,
    imports: [SvgIconComponent],
    templateUrl: './theme-switcher.component.html',
    styleUrl: './theme-switcher.component.scss',
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
    themeService = inject(ThemeSwitcherService)
    isExpanded = input<boolean>(false)

    isDark = signal(false)

    private _onDestroy$ = new Subject()

    ngOnInit(): void {
        this.observeTheme()
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }

    observeTheme() {
        this.themeService.isDarkModeEnabled$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(isDarkeMode => {
                this.isDark.set(isDarkeMode)
            })
    }

    switchTheme() {
        this.themeService.toggleTheme()
    }
}
