<h2 class="emd-text _size_page _color_default _type_bold emd-container__content">
    Gestão de Usuários
</h2>
@if (isInternalUser || isPartnerDataLoaded) {
    <app-account-users-page
        [partner]="partnerData"
        [openedViaBusinessManagement]="false"
    ></app-account-users-page>
} @else {
    <div class="_loading">
        <div class="spinner-border"></div>
        <p class="emd-text _size_section _type_bold _color_subtle">Carregando...</p>
    </div>
}
