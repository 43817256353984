import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-two-factor-force-sms-reset',
    templateUrl: './two-factor-force-sms-reset.component.html',
    styleUrls: ['./two-factor-force-sms-reset.component.scss'],
})
export class TwoFactorForceSmsResetComponent implements OnInit {
    @Output() onNextStep = new EventEmitter()
    @Output() onBackStep = new EventEmitter()
    @Input() authCode!: string

    constructor() {}

    ngOnInit(): void {}

    public handleBackStep() {
        this.onBackStep.emit()
    }

    public handleNextStep() {
        this.onNextStep.emit()
    }
}
