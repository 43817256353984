import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AuthenticationService } from '../../../services/authentication.service'
import { finalize } from 'rxjs'

@Component({
    selector: 'app-two-factor-configuration-step3',
    templateUrl: './two-factor-configuration-step3.component.html',
    styleUrls: ['./two-factor-configuration-step3.component.scss'],
})
export class TwoFactorConfigurationStep3Component implements OnInit {
    authCodeMask = '000 000'
    twoFactorCode: string = ''
    validating2faCode = false
    showValidationErrorFeedback = false

    @Input() authCode!: string
    @Input() authType!: number
    @Input() accountUserEmail!: string

    @Output() onBackStep = new EventEmitter()
    @Output() onNextStep = new EventEmitter()

    constructor(
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
    ) {}

    ngOnInit(): void {}

    public handleBackStep() {
        this.onBackStep.emit()
    }

    unmask2faCode() {
        return this.twoFactorCode.replace(/\D+/g, '')
    }

    public isValidAuthCode() {
        if (this.twoFactorCode) {
            const formattedCode = this.unmask2faCode()
            return formattedCode.toString().length === 6
        }

        return false
    }

    public handleNextStep() {
        if (this.isValidAuthCode()) {
            this.configure2fa()
        } else {
            this.toastr.success('Informe um código de autenticação válido')
        }
    }

    private do2faFlowByAuthType() {
        console.log(this.authType)
        // Direcionar para submit de login
        if (this.authType === 1) {
            //TO-DO fluxo de login
        }

        // Direcionar para submit de configuração
        if (this.authType === 2) {
            this.configure2fa()
        }
    }

    private configure2fa() {
        this.validating2faCode = true
        let configure2faFunctionToCall =
            this.authType === 1
                ? this.authenticationService.activate2faUsingUsingSessionId(
                      this.unmask2faCode(),
                      this.accountUserEmail,
                      this.authCode,
                  )
                : this.authenticationService.activate2faUsingAccessToken(
                      this.unmask2faCode(),
                      this.authCode,
                  )

        configure2faFunctionToCall
            .pipe(
                finalize(() => {
                    this.validating2faCode = false
                }),
            )
            .subscribe(
                data => {
                    const {
                        data: { recovery_code },
                    } = data

                    // @ts-ignore
                    if (data.code === 200) {
                        this.showValidationErrorFeedback = false
                        this.onNextStep.emit(recovery_code)
                    } else {
                        this.toastr.error('Informe um código válido')
                        this.showValidationErrorFeedback = true
                    }
                },
                error => {
                    console.log(error)
                    this.toastr.error('Informe um código válido')
                    this.showValidationErrorFeedback = true
                },
            )
    }
}
