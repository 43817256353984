import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'
import { AccessService } from '../../../core/services/access-service.service'

@Injectable({
    providedIn: 'root',
})
export class HomeRouteGuard implements CanActivate {
    constructor(
        private loggedUserService: ManageLoggedUser,
        private accessService: AccessService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        if (this.loggedUserService.isLoggedIn()) {
            const hasPermission = this.accessService.hasRoles([
                'get-resume-of-operation-by-partner-id',
                'get-resume-of-operation-by-sales-team-member-id',
                'get-resume-of-all-operation',
                'get-commercial-partners-badge',
            ])

            if (!hasPermission) {
                this.router.navigate(['/not-authorized'])
            }

            return hasPermission
        }

        //usuário será redirecionado automaticamente para página de login
        return true
    }
}
