<button
    class="toggle-button"
    [class.expanded]="isSimpleNavigation()"
    (click)="toggleNaveMode($event)"
>
    <i
        class="fa fa-chevron-right fa-small toggle-icon"
        [class.expanded-icon]="isSimpleNavigation()"
        aria-hidden="true"
    ></i>
</button>
