<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Problemas Com o App Autenticador?</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="eu-display_flex eu-column info-container">
            <span class="emd-text">
                Caso esteja com problemas no login com o código de autenticação faça os seguintes
                passos:
            </span>
            <ol class="info-table">
                <li>
                    Acesse no seu smartphone o aplicativo autenticador baixado (Google Autenticador)
                </li>
                <li>
                    Verifique se o código que está inserindo é de fato o relacionado ao Partner Hub
                    cadastrado;
                </li>
                <li>
                    Atenção ao tempo do código no App. Os códigos gerados tem uma duração de 30
                    segundos aproximadamente, e mudam depois deste período;
                </li>
                <li class="eu-cursor_pointer" (click)="handleOpenFAQ()">
                    Acesse nossa FAQ na seção
                    <span style="text-decoration: underline">“Autenticação em duas etapas”</span>
                    para buscar mais informações.
                </li>
            </ol>
        </div>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_center eu-column">
            <button class="emd-btn _color_primary _type_outline w-100" (click)="handleNextStep()">
                Continuo Com Problemas No App De Autenticação
            </button>
            <button class="emd-btn _color_primary w-100" (click)="handleRetryTypeAuthCode()">
                Tentar Novamente
            </button>
        </div>
    </div>
</div>
