import { Component, Output, EventEmitter } from '@angular/core'

@Component({
    selector: 'app-recovery-account-success',
    templateUrl: './recovery-account-success.component.html',
    styleUrls: ['./recovery-account-success.component.scss'],
})
export class RecoveryAccountSuccessComponent {
    @Output() onSuccessRecoveryAccount = new EventEmitter()

    public handleSuccessRecoveryAccount() {
        this.onSuccessRecoveryAccount.emit()
    }
}
