<div class="emd-container step-container">
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <h1 class="emd-text step-title _color_default eu-mb_2">
                <strong>Passo 2: Leitura de QR Code</strong>
            </h1>
            <div class="el-chunk">
                <p class="emd-text">
                    Ao finalizar o download do App de Autenticação, entre e clique em Ler o QR Code.
                </p>
                <p class="emd-text">
                    Aponte seu smartphone para tela para fazer a leitura do QR Code abaixo:
                </p>
            </div>
        </div>
        <div
            class="el-chunk eu-display_flex eu-justify_center eu-align-items_center eu-column"
            style="gap: 1rem"
            *ngIf="isGeneratingQrCode"
        >
            <span class="emd-text _color_primary _size_section"
                >Aguarde, estamos gerando seu QR Code</span
            >
            <div class="spinner-border _color_primary" style="color: var(--color-primary)"></div>
        </div>
        <div *ngIf="!isGeneratingQrCode" class="el-chunk eu-display_flex eu-justify_space-between">
            <img
                src="assets/google-auth-qr-code.svg"
                width="200"
                height="200"
                alt="Imagem do google authenticator"
            />
            <img
                *ngIf="qrCodeText"
                [src]="qrCodeText"
                width="200"
                height="200"
                aria-hidden="true"
            />
        </div>
        <app-two-factor-faq></app-two-factor-faq>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button
                class="emd-btn _color_primary _type_text"
                [disabled]="isGeneratingQrCode"
                (click)="handleBackStep()"
            >
                Voltar
            </button>
            <button
                class="emd-btn _color_primary"
                [disabled]="isGeneratingQrCode"
                (click)="handleNextStep()"
            >
                Continuar
            </button>
        </div>
    </div>
</div>
