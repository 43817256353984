import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
    selector: 'app-recovery-account',
    templateUrl: './recovery-account.component.html',
    styleUrls: ['./recovery-account.component.scss'],
})
export class RecoveryAccountComponent implements OnInit {
    @Input() authCode!: string
    @Input() accountUserEmail!: string
    @Output() onBack = new EventEmitter()
    @Output() onCodelessRecovery = new EventEmitter()
    @Output() onSuccessRecoveryAccount = new EventEmitter()
    hasEnteredInvalidCode = false
    recoveryCode: string = ''
    recoveryCodeMask = 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'

    validatingRecoveryCode = false

    constructor(
        private modalService: NgbModal,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {}

    private unmaskRecoveryCode() {
        return this.recoveryCode.replace(/[^a-zA-Z0-9]/g, '')
    }

    public isValidRecoveryCode() {
        if (this.recoveryCode) {
            const unmaskedRecoveryCode = this.unmaskRecoveryCode()
            return unmaskedRecoveryCode.length === 32
        }
        return false
    }

    public handleRecoveryAccountUser() {
        this.validatingRecoveryCode = true

        if (!this.isValidRecoveryCode()) {
            return
        }

        this.authenticationService
            .reset2fa(this.recoveryCode, this.accountUserEmail, this.authCode)
            .subscribe(
                data => {
                    this.toastr.success('Reset realizado com sucesso!')
                    this.hasEnteredInvalidCode = false
                    this.onSuccessRecoveryAccount.emit()
                },
                response => {
                    if (response?.error?.code === 400) {
                        this.toastr.error('Código de recuperação inválido')
                    } else if (response?.error?.code === 401) {
                        this.toastr.error('Erro na autenticação do reset de 2fa')
                    } else {
                        this.toastr.error('Erro ao realizar reset de 2fa')
                    }

                    this.hasEnteredInvalidCode = true
                },
                () => {
                    this.validatingRecoveryCode = false
                },
            )
    }

    public handleBack() {
        this.onBack.emit()
    }

    public handleCodelessRecovery() {
        this.onCodelessRecovery.emit()
    }
}
