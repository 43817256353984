import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core'
import {
    LoggedUser,
    ManageLoggedUser,
} from '../../../../legacy/authentication/services/manage-logged-user.service'
import { SvgIconComponent } from 'angular-svg-icon'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModal } from '../../../../legacy/core/components/confirm-modal/confirm-modal.component'

@Component({
    selector: 'app-user-avatar',
    standalone: true,
    imports: [SvgIconComponent],
    templateUrl: './user-avatar.component.html',
    styleUrl: './user-avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnInit {
    loggedUserService = inject(ManageLoggedUser)
    modalService = inject(NgbModal)
    router = inject(Router)

    isExpanded = input<boolean>(false)

    logedUser!: LoggedUser

    constructor() {}

    ngOnInit(): void {
        this.logedUser = this.loggedUserService.getLoggedAccountUser().loggedUser
    }

    handleLoggoutConfirmation() {
        const openConfirmModal = this.modalService.open(ConfirmModal, {
            size: 'sm',
            centered: true,
        })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo nos deixar?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) {
                this.loggedUserService.removeLoggedAccountUser()
                this.router.navigate(['/']).then(() => {
                    window.location.reload()
                })
            }
        })
    }
}
