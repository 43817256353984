import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-two-factor-configuration-step1',
    templateUrl: './two-factor-configuration-step1.component.html',
    styleUrls: ['./two-factor-configuration-step1.component.scss'],
})
export class TwoFactorConfigurationStep1Component implements OnInit {
    @Output() onBackStep = new EventEmitter()
    @Output() onNextStep = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    public handleBackStep() {
        this.onBackStep.emit()
    }

    public handleNextStep() {
        this.onNextStep.emit()
    }
}
