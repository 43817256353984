import { DOCUMENT, Location, NgIf } from '@angular/common'
import { Component, Inject, OnInit, signal } from '@angular/core'
import { ActivatedRoute, ActivationStart, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { BaseLayoutComponent } from './core/components/base-layout/base-layout.component'
import { AuthenticationModule } from './legacy/authentication/authentication.module'
import { ManageLoggedUser } from './legacy/authentication/services/manage-logged-user.service'
import { RolesService } from './legacy/authentication/services/roles.service'
import { CoreModule } from './legacy/core/core.module'
import { AccessService } from './legacy/core/services/access-service.service'
import { IntercomService } from './legacy/core/services/intercom/intercom.service'
import { NewAffiliateService } from './legacy/core/services/new-affiliate.service'

declare let gtag: Function

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, AuthenticationModule, NgIf, CoreModule, BaseLayoutComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'partnerhub'

    currentUser: any

    definePasswordSetVisible = false
    definePasswordValidationToken: any

    modulesLoaded = false
    publicRoute = signal(false)
    isLoading = signal(true)

    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private loggedUserService: ManageLoggedUser,
        private affiliateService: NewAffiliateService,
        private accessService: AccessService,
        public rolesService: RolesService,
        private intercomService: IntercomService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof ActivationStart) {
                if (event.snapshot.data['isPublicRoute']) {
                    this.publicRoute.set(event.snapshot.data['isPublicRoute'])
                } else {
                    const accountUserIsLogged = this.loggedUserService.getLoggedAccountUser().success
                    if (accountUserIsLogged) {
                        this.currentUser = this.loggedUserService.getLoggedAccountUser()
                        this.loadPermission()
                    }
                }
            }
            if (event instanceof NavigationEnd) {
                if (this.router.url.includes('validate')) {
                    this.route.queryParams.subscribe(params => {
                        if (params['token']) {
                            this.definePasswordValidationToken = params['token']
                            this.definePasswordSetVisible = true
                        }
                    })
                }
                if (this.loggedUserService.isLoggedIn()) {
                    const loggedUser = this.loggedUserService.getLoggedAccountUser().loggedUser
                    const normalizePartner = loggedUser?.account_user_partner
                        ? loggedUser.account_user_partner.normalize('NFD').replace(/[^\w\s]/gi, '')
                        : ''
                    gtag('event', 'page_view', {
                        page_title: event.urlAfterRedirects,
                        page_path: event.urlAfterRedirects,
                        page_location: this.document.location.href,
                        user_properties: {
                            user_name: loggedUser.account_user_name,
                            user_email: loggedUser.account_user_email,
                            partner_name: normalizePartner,
                            user_type: loggedUser.account_user_role_type,
                        },
                    })
                }
                this.isLoading.set(false)
            }
        })
    }

    loadPermission() {
        this.rolesService.load().subscribe({
            error: () => {
                this.toastr.error('Erro na validação de permissões de usuário')
            },
        })

        this.loadPartnerModules()
        this.intercomService.init()
    }

    loadPartnerModules() {
        if (this.accessService.isInternalUser()) {
            this.setModulesLoaded()
        } else {
            this.affiliateService.getRelatedPartnerModules().subscribe(({ data }) => {
                if (!data || !data.enabledModules) {
                    return
                }

                this.loggedUserService.setUserModules(data.enabledModules)
                this.setModulesLoaded()
            })
        }
    }

    private setModulesLoaded() {
        this.modulesLoaded = true
    }

    singInAccountUser(accountUser: any) {
        this.currentUser = accountUser
        this.loadPermission()
    }
}
