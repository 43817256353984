import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AuthenticationService } from '../../../services/authentication.service'
import { finalize } from 'rxjs'

@Component({
    selector: 'app-two-factor-configuration-step2',
    templateUrl: './two-factor-configuration-step2.component.html',
    styleUrls: ['./two-factor-configuration-step2.component.scss'],
})
export class TwoFactorConfigurationStep2Component implements OnInit {
    @Input() authCode!: string
    @Input() authType: number = -1
    @Input() accountUserEmail!: string
    @Output() onBackStep = new EventEmitter()
    @Output() onNextStep = new EventEmitter()

    isGeneratingQrCode = false
    qrCodeText!: string

    constructor(
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.request2faQrCode()
    }

    request2faQrCode() {
        this.isGeneratingQrCode = true
        this.generate2faConfigurationQrCode()
    }

    private generate2faConfigurationQrCode() {
        const generateQrCodeFunctionToCall =
            this.authType === 1
                ? this.authenticationService.generate2fConfigurationQrCodeUsingSessionId(
                      this.authCode,
                      this.accountUserEmail,
                  )
                : this.authenticationService.generate2fConfigurationQrCodeUsingAccessToken(
                      this.authCode,
                  )

        generateQrCodeFunctionToCall
            .pipe(
                finalize(() => {
                    this.isGeneratingQrCode = false
                }),
            )
            .subscribe(
                ({ code, data: { qr_code } }) => {
                    if (code === 200) {
                        this.qrCodeText = 'data:image/jpg;base64,' + qr_code
                    } else {
                        this.toastr.error('Ocorreu uma falha ao tentar gerar o QR Code')
                    }
                },
                error => {
                    this.toastr.error('Ocorreu uma falha ao tentar gerar o QR Code')
                },
                () => {},
            )
    }

    public handleBackStep() {
        this.onBackStep.emit()
    }

    public handleNextStep() {
        this.onNextStep.emit()
    }
}
