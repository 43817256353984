<div class="avatar-container">
    <img src="assets/icons/default-user-profile-img.png" />
</div>
<div class="eu-display_flex eu-column user-info" [class.expanded]="isExpanded()">
    <span class="emd-text _type_bold">{{ logedUser.account_user_name }}</span>
    <span class="emd-text _size_small">{{ logedUser.account_user_role_type_alias }}</span>
</div>

@if (isExpanded()) {
    <button
        id="signout-button"
        class="emd-btn _type_text signout-button"
        (click)="handleLoggoutConfirmation()"
    >
        <i class="fa fa-sign-out signout-icon" aria-hidden="true"></i>
    </button>
}
