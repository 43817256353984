<div>
    <app-two-factor-force-sms-reset
        *ngIf="!startReset"
        [authCode]="authCode"
        (onBackStep)="handleBackStep()"
        (onNextStep)="handleNextStep()"
    >
    </app-two-factor-force-sms-reset>
    <app-two-factor-execute-force-sms-reset
        *ngIf="startReset"
        [authCode]="authCode"
        (onBackStep)="restartResetProcess()"
        (onSuccessfulReset)="handleBackStep()"
    >
    </app-two-factor-execute-force-sms-reset>
</div>
