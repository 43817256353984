import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-two-factor-configuration-step4',
    templateUrl: './two-factor-configuration-step4.component.html',
    styleUrls: ['./two-factor-configuration-step4.component.scss'],
})
export class TwoFactorConfigurationStep4Component implements OnInit {
    @Input() recoveryCode!: string
    @Output() onNextStep = new EventEmitter()

    constructor(private toastr: ToastrService) {}

    ngOnInit(): void {}

    public handleNextStep() {
        this.onNextStep.emit()
    }

    public handleCopyRecoveryCodeToClipboard() {
        navigator.clipboard.writeText(this.recoveryCode).then(() => {
            this.toastr.success('O código de recuperação foi copiado com sucesso!')
        })
    }
}
