<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <div
            class="emd-tag success-title-tag eu-display_flex eu-justify_center eu-align-items_center"
        >
            <div><img src="/assets/icons/accepted.svg" /></div>
            <h1 class="emd-text step-title">Autenticação Configurada Com Sucesso!</h1>
        </div>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <div class="el-chunk">
                <p class="emd-text">
                    A sua autenticação em duas etapas foi concluída. Agora sempre que realizar o
                    login conosco, acesse o aplicativo autenticador e nos informe o código de 6
                    dígitos que constar no app.
                </p>
            </div>
        </div>
        <div class="el-chunk auth-code-card">
            <div class="el-chunk">
                <div class="w-100 eu-display_flex eu-column" style="gap: 0.5rem">
                    <p class="emd-text" style="font-size: 0.75rem">
                        Caso perca acesso ao celular, basta usar o código de recuperação abaixo:
                    </p>
                    <div
                        class="eu-display_flex eu-justify_center eu-align-items_center eu-column w-100"
                    >
                        <span class="emd-text _size_small _type_bold">Código de Recuperação</span>
                        <div class="recovery-code-card w-100 eu-display_flex eu-justify_center">
                            <div class="w-100 recovery-code-center">
                                <span class="emd-text">{{ recoveryCode }}</span>
                            </div>
                            <div
                                title="Copiar para área de transferência"
                                class="self-end eu-cursor_pointer"
                                (click)="handleCopyRecoveryCodeToClipboard()"
                            >
                                <i class="fa fa-clone" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="emd-tag _color_warning w-100 warning-card eu-display_flex eu-align-items_baseline"
            >
                <div class="eu-pb_4">
                    <img src="/assets/icons/warning-icon.svg" alt="Ícone de aviso" />
                </div>
                <div class="eu-display_flex eu-column warnings eu-column" style="text-align: start">
                    <span class="emd-text _size_section">IMPORTANTE!</span>
                    <ul>
                        <li>Geramos o código de recuperação apenas uma única vez;</li>
                        <li>Copie e guarde-o em um lugar seguro no seu sistema;</li>
                        <li>Nunca compartilhe este código com outras pessoas.</li>
                    </ul>
                </div>
            </div>
        </div>

        <app-two-factor-faq class="eu-mt_4"></app-two-factor-faq>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button class="emd-btn _color_primary" (click)="handleNextStep()">Concluir</button>
        </div>
    </div>
</div>
