<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Digite Seu Código de Autenticação</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="el-chunk eu-display_flex eu-justify_center">
            <img src="assets/google-auth-codes.svg" alt="Imagem do google authenticator" />
        </div>
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <div class="el-chunk">
                <p class="emd-text">
                    Digite o código de 6 dígitos que aparece no seu aplicativo de autenticação ou
                    SMS
                </p>
            </div>
        </div>
        <div class="el-chunk">
            <label class="emd-label">Código de Autenticação</label>
            <input
                [ngClass]="{ _state_error: showValidationErrorFeedback }"
                class="emd-input"
                placeholder="000 000"
                [mask]="authCodeMask"
                [(ngModel)]="twoFactorCode"
            />
            <div
                class="eu-display_flex eu-align-items_center eu-mt_2"
                style="gap: 0.5rem"
                *ngIf="showValidationErrorFeedback"
            >
                <i
                    class="fa fa-times-circle"
                    style="color: var(--color-danger)"
                    aria-hidden="true"
                ></i>
                <p class="emd-input-feedback _state_error">
                    Não conseguimos identificar o código de autenticação. Verifique e tente
                    novamente.
                </p>
            </div>
        </div>
        <app-two-factor-faq></app-two-factor-faq>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button
                class="emd-btn _color_primary _type_text"
                [disabled]="validating2faCode"
                (click)="handleBackStep()"
            >
                Voltar
            </button>
            <button
                class="emd-btn _color_primary"
                (click)="handleNextStep()"
                [disabled]="!isValidAuthCode() || validating2faCode"
            >
                <span *ngIf="!validating2faCode"> Continuar</span>
                <app-dot-loading
                    *ngIf="validating2faCode"
                    [isLoading]="validating2faCode"
                ></app-dot-loading>
            </button>
        </div>
    </div>
</div>
