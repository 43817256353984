<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Código de Recuperação</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <div class="el-chunk">
                <p class="emd-text">
                    Ao realizar a configuração da sua autenticação em duas etapas, lhe fornecemos um
                    código de recuperação. Por meio deste é possível recuperar sua conta e realizar
                    o login.
                </p>
            </div>
        </div>
        <div class="el-chunk">
            <label class="emd-label"><strong>Código de Recuperação</strong></label>
            <input
                [ngClass]="{ _state_error: hasEnteredInvalidCode }"
                class="emd-input"
                placeholder="Digite o seu código de recuperação para continuar"
                [mask]="recoveryCodeMask"
                [(ngModel)]="recoveryCode"
                autofocus
            />
            <div
                class="eu-display_flex eu-align-items_center eu-mt_2"
                style="gap: 0.5rem"
                *ngIf="hasEnteredInvalidCode"
            >
                <i
                    class="fa fa-times-circle"
                    style="color: var(--color-danger)"
                    aria-hidden="true"
                ></i>
                <p class="emd-input-feedback _state_error">
                    Não conseguimos identificar o código de recuperação. Verifique e tente
                    novamente.
                </p>
            </div>
        </div>
        <div class="el-chunk eu-cursor_pointer" (click)="handleCodelessRecovery()">
            <span class="emd-text _color_primary"
                >Não tenho acesso ao meu código de recuperação</span
            >
        </div>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button
                class="emd-btn _color_primary _type_text"
                [disabled]="validatingRecoveryCode"
                (click)="handleBack()"
            >
                Voltar
            </button>
            <button
                class="emd-btn _color_primary"
                [disabled]="!isValidRecoveryCode() || validatingRecoveryCode"
                (click)="handleRecoveryAccountUser()"
            >
                <span *ngIf="!validatingRecoveryCode"> Continuar</span>
                <app-dot-loading
                    *ngIf="validatingRecoveryCode"
                    [isLoading]="validatingRecoveryCode"
                ></app-dot-loading>
            </button>
        </div>
    </div>
</div>
