<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Recuperação de Conta</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <span class="emd-text">
            Para recuperar a sua conta sem acesso aos códigos de recuperação, entre em contato com
            nosso time de suporte pelo email: {{ 'souparceiro@stone.com.br' }}
        </span>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex eu-justify_space-between eu-column w-100">
            <div class="eu-display_flex footer-gap w-100 eu-justify_center eu-column">
                <button
                    class="emd-btn _color_primary _type_outline w-100"
                    (click)="handleCopySupportEmail()"
                >
                    Copiar E-Mail do Suporte
                </button>
                <button class="emd-btn _color_primary w-100" (click)="handleRetryTypeAuthCode()">
                    Tentar Novamente
                </button>
            </div>
            <app-two-factor-faq></app-two-factor-faq>
        </div>
    </div>
</div>
