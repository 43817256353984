import { Component, OnInit } from '@angular/core'

export const Faq = {
    uri: 'https://stone-partnerprogram.helpjuice.com/pt_BR/seguran%C3%A7a-e-chaves/autentica%C3%A7%C3%A3o-em-duas-etapas',
}

@Component({
    selector: 'app-two-factor-faq',
    templateUrl: './two-factor-faq.component.html',
    styleUrls: ['./two-factor-faq.component.scss'],
})
export class TwoFactorFaqComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    public handleOpenFAQ() {
        window.open(Faq.uri, '_blank')
    }
}
