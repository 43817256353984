import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export enum RecoveryAccountSteps {
    STEP_1,
    STEP_2,
    STEP_3,
    CODELESS_RECOVERY,
}

@Component({
    selector: 'app-login-problems-page',
    templateUrl: './login-problems-page.component.html',
    styleUrls: ['./login-problems-page.component.scss'],
})
export class LoginProblemsPageComponent implements OnInit {
    @Input() authCode!: string
    @Input() accountUserEmail!: string
    @Output() onRetryTypeAuthCode = new EventEmitter()
    @Output() onSuccessRecoveryAccount = new EventEmitter()

    currentStep = RecoveryAccountSteps.STEP_1
    recoveryAccountSteps = RecoveryAccountSteps

    constructor() {}

    ngOnInit(): void {
        this.currentStep = RecoveryAccountSteps.STEP_1
    }

    public handleNextStep() {
        this.currentStep += 1
    }

    public handleBackStep() {
        this.currentStep -= 1
    }

    public handleRetryTypeAuthCode() {
        this.onRetryTypeAuthCode.emit()
    }

    public handleSuccessRecoveryAccount() {
        this.onSuccessRecoveryAccount.emit()
    }

    public handleCodelessRecovery() {
        this.currentStep = RecoveryAccountSteps.CODELESS_RECOVERY
    }
}
