import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'
import { AccessService } from '../../../core/services/access-service.service'

@Injectable({
    providedIn: 'root',
})
export class CreateStoneAccountRouteGuard implements CanActivate {
    enabledModules: any[] = []
    constructor(
        private loggedUserService: ManageLoggedUser,
        private accessService: AccessService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        return this.loggedUserService.isLoggedIn() ? this.checkPermission() : this.redirectToLogin()
    }

    private checkPermission(): Observable<boolean> | boolean {
        const hasPermission = this.accessService.hasRoles([
            'allow-create-stone-account',
            'allow-salesman-create-stone-account',
            'allow-owner-create-stone-account',
        ])

        return hasPermission ? true : this.redirectToUnauthorized()
    }

    private redirectToLogin(): boolean {
        this.router.navigate(['/'])
        return false
    }

    private redirectToUnauthorized(): boolean {
        this.router.navigate(['/not-authorized'])
        return false
    }
}
