import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from '../../services/authentication.service'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'
import { EventEmitterService } from '../../../core/services/eventemiter.service'
import { ConfirmModal } from '../../../core/components/confirm-modal/confirm-modal.component'
import { AuthenticationComponent } from '../login/authentication.component'

@Component({
    selector: 'logged-user',
    templateUrl: './logged-user.component.html',
    styleUrl: './logged-user.component.scss',
})
export class LoggedUser implements OnInit {
    userName: string = ''
    userEmail: string = ''
    userPartnerAssociated: string = ''
    userAccessType: string = ''

    constructor(
        private modalService: NgbModal,
        private authenticationService: AuthenticationService,
        private manageLoggedUser: ManageLoggedUser,
    ) {}

    ngOnInit() {
        EventEmitterService.get('urlNavbarActive').emit('listMyDataActive')
        this.validateDisplayLoggedUser()
    }

    validateDisplayLoggedUser() {
        this.authenticationService.getAccountUserPermissions().subscribe(
            data => {
                let roleType = data['account_user_roles'].role_type
                if (roleType == 'user-admin-app') this.userAccessType = 'Administrador Partner Hub'
                if (roleType == 'user-bizdev') this.userAccessType = 'Bizdev'
                if (roleType == 'user-admin-partner') this.userAccessType = 'Administrador Parceiro'
                if (roleType == 'user-sales-person') this.userAccessType = 'Vendedor'

                if (roleType == 'user-admin-partner' || roleType == 'user-sales-person') {
                    let partnerId = ''
                    let partnerName = ''
                    data['account_user_roles'].roles.map((role: any) => {
                        if (role.type == 'partner-id') partnerId = role.value
                        if (role.type == 'partner-name') partnerName = role.value
                    })
                    this.userPartnerAssociated = `${partnerId} - ${partnerName}`
                }

                let loggedUser: any = this.manageLoggedUser.getLoggedAccountUser()
                if (loggedUser.success) {
                    loggedUser = loggedUser.loggedUser
                    this.userName = loggedUser['account_user_name']
                    this.userEmail = loggedUser['account_user_email']
                }
            },
            error => {
                console.log(error)
            },
        )
    }

    confirmLogout() {
        let openConfirmModal = this.modalService.open(ConfirmModal, { size: 'sm' })
        openConfirmModal.componentInstance.confirmationTitle = 'Deseja mesmo nos deixar?'

        openConfirmModal.result.then(result => {
            if (result.confirmation) this.logout()
        })
    }

    logout() {
        let loggedUser: any = this.manageLoggedUser.getLoggedAccountUser()
        if (loggedUser.success) {
            loggedUser = loggedUser.loggedUser
        }

        this.authenticationService
            .logOutAccountUser(loggedUser['account_user_session_token'])
            .subscribe(
                data => {
                    if (data.success) {
                        this.manageLoggedUser.removeLoggedAccountUser()
                        window.location.href = '/'
                    }
                },
                error => {
                    this.manageLoggedUser.removeLoggedAccountUser()
                    window.location.href = '/'
                },
            )
    }
}
