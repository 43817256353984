import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-codeless-access-recovery',
    templateUrl: './codeless-access-recovery.component.html',
    styleUrls: ['./codeless-access-recovery.component.scss'],
})
export class CodelessAccessRecoveryComponent implements OnInit {
    @Output() onRetryTypeAuthCode = new EventEmitter()

    constructor(private toastr: ToastrService) {}

    ngOnInit(): void {}

    public handleCopySupportEmail() {
        navigator.clipboard.writeText('souparceiro@stone.com.br').then(() => {
            this.toastr.success('O E-mail suporte foi copiado com sucesso!')
        })
    }

    public handleRetryTypeAuthCode() {
        this.onRetryTypeAuthCode.emit()
    }
}
