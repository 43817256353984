<div [ngSwitch]="currentStep">
    <app-two-factor-configuration-step1
        *ngSwitchCase="twoFactorSteps.STEP_1"
        (onNextStep)="handleNextStep($event)"
        (onBackStep)="handleBackStep()"
    ></app-two-factor-configuration-step1>
    <app-two-factor-configuration-step2
        *ngSwitchCase="twoFactorSteps.STEP_2"
        [accountUserEmail]="accountUserEmail"
        [authType]="authType"
        [authCode]="authCode"
        (onNextStep)="handleNextStep($event)"
        (onBackStep)="handleBackStep()"
    ></app-two-factor-configuration-step2>
    <app-two-factor-configuration-step3
        *ngSwitchCase="twoFactorSteps.STEP_3"
        [accountUserEmail]="accountUserEmail"
        [authType]="authType"
        [authCode]="authCode"
        (onNextStep)="handleNextStep($event)"
        (onBackStep)="handleBackStep()"
    ></app-two-factor-configuration-step3>
    <app-two-factor-configuration-step4
        *ngSwitchCase="twoFactorSteps.STEP_4"
        [recoveryCode]="recoveryCode"
        (onNextStep)="handleNextStep($event)"
    ></app-two-factor-configuration-step4>
</div>
