import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'
import { AccessService } from '../../../core/services/access-service.service'

@Injectable({
    providedIn: 'root',
})
export class DashboardRouteGuard implements CanActivate {
    constructor(
        private loggedUserService: ManageLoggedUser,
        private accessService: AccessService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | boolean {
        if (this.loggedUserService.isLoggedIn()) {
            const hasPermission = this.accessService.hasRoles([
                'get-total-payment-volume',
                'get-credential-amount',
                'get-affiliates-automatic-anticipation',
                'get-active-base',
            ])

            if (!hasPermission) {
                this.router.navigate(['/not-authorized'])
            }

            return hasPermission
        }

        this.router.navigate(['/'])
        return false
    }
}
