import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { ManageLoggedUser } from '../../services/manage-logged-user.service'
import { AuthenticationService } from '../../services/authentication.service'
import { ErrorListArrayMessageParser } from '../../../core/services/error-list-array-message-parser.service'
import { environment } from '../../../../../environments/environment'
import { RequestLoginTokenReturn } from '../../types/AuthenticationTypes'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'

export interface FormModel {
    captcha?: string
}

@Component({
    selector: 'authentication-component',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
    versionApp = environment.appVersion
    // Visualização
    inputPasswordSetVisible = false
    showPassword = false
    signInLoaderSetVisible = false
    resetPasswordSetVisible = false
    resetPasswordSentMessageSetVisible = false
    preRegisterOldUserMessageSetVisible = false
    configure2faQrCodeSetVisible = false
    recoveryCode2faSetVisible = false
    require2faSetVisible = false
    reset2faSetVisible = false
    forceResetSms2fa = false
    isRecaptcha = true //Acordado com o time de segurança da Stone que o recaptcha sempre precisa ser true // By Caio Santos
    captchaResponse: any = null
    captchaResponseValidCount = 0

    public configure2faIsVisible = false

    public formModel: FormModel = {}

    // Usuário
    accountUserEmail = ''
    accountUserName = ''
    accountUserPassword: string | null = ''
    authorizedAccountUser: any = null

    // 2fa
    getAccessTokenReturn: RequestLoginTokenReturn | null = null
    require2fa = false
    code2fa: string | null = null
    recoveryCode2fa: string | null = null
    configuration2faAuthType = -1
    configuration2faAuth: string | null = null
    user2faConfigureIsOn = false
    succeeded2faConfiguration = false
    invalid2faCodeFeedback = false

    // Output do resultado do login
    @Output() singInAccountUser = new EventEmitter<{}>()
    @ViewChild('inputUserPasswordFrame') inputUserPasswordFrame!: ElementRef<HTMLInputElement>

    constructor(
        private authenticationService: AuthenticationService,
        private manageLoggedUser: ManageLoggedUser,
        private toastr: ToastrService,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    ngOnInit() {
        console.log(`A versão da aplicação é: ${this.versionApp}`)
    }

    getUserAccessSessionStatus() {
        const loggedUser = this.manageLoggedUser.getLoggedAccountUser()
        return loggedUser.success
    }

    onAuthorizeAccountUser(twoFactorCode: string) {
        this.code2fa = twoFactorCode
        this.authorizeAccountUser()
    }

    authorizeAccountUser() {
        if (!this.accountUserPassword) {
            return
        }
        if (this.signInLoaderSetVisible) {
            return
        }

        if (this.captchaResponse) {
            this.captchaResponseValidCount += 1
        }

        this.signInLoaderSetVisible = true
        this.authenticationService
            .authorizeAccountUser(
                this.accountUserEmail,
                this.accountUserPassword,
                this.captchaResponse,
                this.code2fa,
                this.getAccessTokenReturn?.response?.session_id,
            )
            .subscribe(
                data => {
                    // @ts-ignore
                    if (data.access_token) {
                        this.signInLoaderSetVisible = false
                        this.handle2faConfigurationActions(data)
                    }

                    // this.invalid2faCodeFeedback = false
                },
                (response: HttpErrorResponse) => {
                    this.signInLoaderSetVisible = false
                    if (
                        response.error?.error_description &&
                        response.error?.error_description.includes('mfa') &&
                        response.error?.error_description !== 'mfa code is invalid'
                    ) {
                        this.getAccessTokenReturn = response.error
                        this.handle2faAction()
                    } else if (response.error?.error_description && response.error?.error_description === 'mfa code is invalid') {
                        this.toastr.error('Código de autorização incorreto')
                        this.code2fa = null
                        this.invalid2faCodeFeedback = true
                    } else {
                        this.toastr.error('Usuário ou senha incorretos')
                        if (response.error && response.status == 400) {
                            this.isRecaptcha = true
                            this.accountUserPassword = null
                            this.formModel.captcha = ''
                            this.captchaResponse = null
                        }
                    }
                },
            )
    }

    handle2faConfigurationActions(loginTokenReturn: RequestLoginTokenReturn) {
        const { access_token } = loginTokenReturn

        if (access_token) {
            this.authenticationService.get2faConfigurationUsingAccessToken(access_token).subscribe(
                data => {
                    const {
                        data: { phone_number },
                    } = data
                    const haveHiddenSmsConfiguration = !!phone_number
                    this.checkIfNeedsToConfigure2fa(loginTokenReturn, haveHiddenSmsConfiguration)
                },
                error => {
                    this.checkIfNeedsToConfigure2fa(loginTokenReturn, false)
                },
            )
        }

        if (!access_token) this.checkIfNeedsToConfigure2fa(loginTokenReturn, false)
    }

    checkIfNeedsToConfigure2fa(loginTokenReturn: RequestLoginTokenReturn, haveSms2faConfiguration: boolean) {
        const { access_token } = loginTokenReturn

        const mustConfigure = () => {
            /* se foi identificado como usuário com config de 2fa ativa e retornou token, tem que logar direto */
            if (this.user2faConfigureIsOn && access_token) return false

            /* se foi identificado como usuário que não deve configurar 2fa obrigatoriamente e retornou token, tem que logar direto */
            if (!this.require2fa && access_token) return false

            /* se foi identificado como usuário acabou de configurar 2fa e retornou token, tem que logar direto */
            if (this.succeeded2faConfiguration && access_token) return false

            /* se foi identificado como usuário que deve configurar 2fa obrigatoriamente e retornou token, tem que configurar */
            if (this.require2fa && access_token) return true

            return false
        }

        const configure2Fa = mustConfigure()

        if (configure2Fa && !haveSms2faConfiguration) {
            this.getAccessTokenReturn = loginTokenReturn
            this.handle2faAction()
        }

        if (!configure2Fa && !haveSms2faConfiguration) {
            this.authorizedAccountUser = loginTokenReturn
            this.singIn()
        }

        if (configure2Fa && haveSms2faConfiguration) {
            this.forceResetSms2fa = true
            this.configuration2faAuth = access_token
        }

        this.invalid2faCodeFeedback = false
    }

    /* Esta função é chamada apenas pelo componente de definição de senha no momento em que seu processo for concluído com sucesso */
    authorizeAccountUserAfterPreRegisterValidated(accountUserEmail: string, accountUserPassword: string) {
        if (!accountUserPassword || !accountUserEmail) {
            return
        }
        if (this.signInLoaderSetVisible) {
            return
        }

        this.signInLoaderSetVisible = true
        this.authenticationService
            .authorizeAccountUser(
                accountUserEmail,
                accountUserPassword,
                this.captchaResponse,
                this.code2fa,
                this.getAccessTokenReturn?.response?.session_id,
            )
            .subscribe(
                data => {
                    // @ts-ignore
                    if (data.access_token) {
                        this.authorizedAccountUser = data
                        this.singIn()
                    }
                },
                error => {
                    this.toastr.error('Usuário ou senha incorretos')
                    console.log(error)
                },
                () => {
                    this.signInLoaderSetVisible = false
                },
            )
    }

    resolved(captchaResponse: string | null) {
        if (captchaResponse) {
            this.captchaResponse = captchaResponse
            return
        }
        this.captchaResponse = null
    }

    error(error: any) {
        this.captchaResponse = null
    }

    verifyIfAccountUserEmailExists() {
        this.accountUserPassword = null
        this.signInLoaderSetVisible = true
        this.authenticationService.verifyIfAccountUserEmailExists(this.accountUserEmail).subscribe(
            data => {
                this.signInLoaderSetVisible = false

                const { success } = data

                if (!success) {
                    this.signInLoaderSetVisible = false

                    this.errorListMessageParser.showErrorListMessageOrDefault(data.errorList, 'Erro não mapeado')

                    return
                }

                this.require2fa = true
                this.inputPasswordSetVisible = true
                // @ts-ignore
                this.accountUserName = data.account_user_name
            },
            (response: HttpErrorResponse) => {
                this.signInLoaderSetVisible = false
                this.errorListMessageParser.showErrorListMessageOrDefault(response.error?.errorList, 'Falha não mapeada')
            },
        )
    }

    resetPasswordSetVisibleTrue() {
        this.resetPasswordSetVisible = true
    }

    sendResetAccountUserPassword() {
        if (this.signInLoaderSetVisible) {
            return
        }
        this.signInLoaderSetVisible = true
        this.authenticationService.sendResetAccountUserPassword(this.accountUserEmail).subscribe(
            (data: HttpResponse<any>) => {
                if (data['status'] == 201) {
                    this.signInLoaderSetVisible = false
                    this.resetPasswordSentMessageSetVisible = true
                    this.signInLoaderSetVisible = false
                    this.toastr.success('Em instantes será enviado um E-mail para definir uma nova senha', 'Redefinição de Senha')
                } else {
                    this.signInLoaderSetVisible = false
                    this.resetPasswordSetVisible = false
                    this.toastr.error('Erro ao realizar reset de senha. Tente novamente mais tarde')
                }
            },
            error => {
                this.signInLoaderSetVisible = false
                this.resetPasswordSetVisible = false
                this.toastr.error('Erro ao realizar reset de senha. Tente novamente mais tarde')
                console.log(error)
            },
        )
    }

    singIn() {
        if (!this.authorizedAccountUser) {
            return
        }
        /* Enviar para a API registrar a sessão e retornar o token do usuário organizado para ser armazenado em local storage */
        this.authenticationService.singInAccountUser(this.authorizedAccountUser.access_token).subscribe(
            data => {
                if (data.success) {
                    // @ts-ignore
                    if (data.authenticated_account_user_session) {
                        // @ts-ignore
                        const authorizedAccountUser = data.authenticated_account_user_session
                        this.manageLoggedUser.setLoggedAccountUser(authorizedAccountUser)
                        this.singInAccountUser.emit(authorizedAccountUser)
                    }
                } else {
                    this.toastr.error('Problemas ao realizar login de usuário')
                    console.log(data)
                }
            },
            error => {
                this.signInLoaderSetVisible = false
                this.toastr.error('Usuário ou senha incorretos')
                console.log(error)
            },
        )
    }

    logout() {
        let loggedUser: any = this.manageLoggedUser.getLoggedAccountUser()
        if (loggedUser.success) {
            loggedUser = loggedUser.loggedUser
        }

        this.authenticationService.logOutAccountUser(loggedUser['account_user_session_token']).subscribe(
            data => {
                if (data.success) {
                    this.manageLoggedUser.removeLoggedAccountUser()
                    window.location.href = '/'
                }
            },
            error => {
                this.manageLoggedUser.removeLoggedAccountUser()
                window.location.href = '/'
            },
        )
    }

    inputEnterKeyAction(keyPressed: any, inputOrigin: any) {
        if (keyPressed.keyCode !== 13) {
            return
        }
        if (inputOrigin == 'email') {
            if (!this.accountUserEmail) {
                return
            }
            this.verifyIfAccountUserEmailExists()
        }

        if (inputOrigin == 'password') {
            if (!this.accountUserPassword || (this.isRecaptcha && !this.captchaResponse)) {
                return
            }
            this.authorizeAccountUser()
        }
    }

    showInputUserEmailFrame() {
        return (
            !this.inputPasswordSetVisible &&
            !this.resetPasswordSetVisible &&
            !this.preRegisterOldUserMessageSetVisible &&
            !this.configure2faQrCodeSetVisible &&
            !this.configure2faIsVisible &&
            !this.require2faSetVisible &&
            !this.recoveryCode2faSetVisible &&
            !this.reset2faSetVisible &&
            !this.forceResetSms2fa
        )
    }

    showInputUserPasswordFrame() {
        const shouldShow = this.inputPasswordSetVisible && !this.resetPasswordSetVisible && !this.forceResetSms2fa

        if (shouldShow) this.inputUserPasswordFrame.nativeElement.focus()

        return shouldShow
    }

    show2faResetFrame() {
        this.require2faSetVisible = false
        this.reset2faSetVisible = true
    }

    handle2faAction() {
        // @ts-ignore
        const { access_token, error_description } = this.getAccessTokenReturn

        this.configuration2faAuthType = -1
        this.configuration2faAuth = null
        this.succeeded2faConfiguration = false

        if (access_token || (error_description && error_description === 'mfa is required')) {
            this.user2faConfigureIsOn = false
            this.configure2faIsVisible = true
            this.inputPasswordSetVisible = false
        }

        if (error_description && error_description === 'mfa code is required') {
            this.user2faConfigureIsOn = true
            this.require2faSetVisible = true
            this.inputPasswordSetVisible = false
        }

        this.configuration2faAuthType = access_token ? 2 : 1

        if (this.configuration2faAuthType === 1) {
            this.configuration2faAuth = this.getAccessTokenReturn ? this.getAccessTokenReturn.response.session_id : null
        }
        if (this.configuration2faAuthType === 2) {
            this.configuration2faAuth = access_token
        }
    }

    require2faCode() {
        this.require2faSetVisible = true
        this.configure2faQrCodeSetVisible = false
        this.reset2faSetVisible = false
    }

    restartLoginProcess() {
        this.inputPasswordSetVisible = false
        this.resetPasswordSetVisible = false
        this.preRegisterOldUserMessageSetVisible = false
        this.configure2faQrCodeSetVisible = false
        this.configure2faIsVisible = false
        this.require2faSetVisible = false
        this.recoveryCode2faSetVisible = false
        this.reset2faSetVisible = false

        this.recoveryCode2fa = null
        this.code2fa = null
        this.user2faConfigureIsOn = false
        this.succeeded2faConfiguration = false
        this.forceResetSms2fa = false
    }

    handleBackToLogin() {
        this.configure2faIsVisible = false
        this.require2faSetVisible = false
        this.invalid2faCodeFeedback = false
        this.inputPasswordSetVisible = true
    }

    handleFinish2faConfiguration() {
        this.restartLoginProcess()
    }

    handleLoginProblems() {
        this.show2faResetFrame()
    }

    handleRetryTypeAuthCode() {
        this.invalid2faCodeFeedback = false
        this.require2faCode()
    }
}
