import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Faq } from '../two-factor-faq/two-factor-faq.component'

@Component({
    selector: 'app-login-problems-info',
    templateUrl: './login-problems-info.component.html',
    styleUrls: ['./login-problems-info.component.scss'],
})
export class LoginProblemsInfoComponent implements OnInit {
    @Output() onHandleNextStep = new EventEmitter()
    @Output() onRetryTypeAuthCode = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    public handleNextStep() {
        this.onHandleNextStep.emit()
    }

    public handleRetryTypeAuthCode() {
        this.onRetryTypeAuthCode.emit()
    }

    public handleOpenFAQ() {
        window.open(Faq.uri, '_blank')
    }
}
