import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
    selector: 'app-two-factor-execute-force-sms-reset',
    templateUrl: './two-factor-execute-force-sms-reset.component.html',
    styleUrls: ['./two-factor-execute-force-sms-reset.component.scss'],
})
export class TwoFactorExecuteForceSmsResetComponent implements OnInit {
    authCodeMask = '000 000'
    twoFactorCode!: string
    validating2faCode = false
    showValidationErrorFeedback = false

    @Output() onBackStep = new EventEmitter()
    @Output() onSuccessfulReset = new EventEmitter()
    @Input() authCode!: string

    constructor(
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
    ) {}

    ngOnInit(): void {
        this.send2faCodeSms()
    }

    unmask2faCode() {
        return this.twoFactorCode?.replace(/\D+/g, '')
    }

    public isValidAuthCode() {
        if (this.twoFactorCode) {
            const formattedCode = this.unmask2faCode()
            return formattedCode?.toString().length === 6
        }

        return false
    }

    private executeReset() {
        this.authenticationService
            .remove2faUsingAccessToken(this.unmask2faCode(), this.authCode)
            .subscribe(data => {
                this.toastr.success('Autenticação de dois fatores redefinida com sucesso')
                this.onSuccessfulReset.emit()
            })
    }

    private send2faCodeSms() {
        this.authenticationService.send2faSmsUsingAccessToken(this.authCode).subscribe(
            data => {
                console.log('SMS de reset enviado com sucesso')
                console.log(data)
            },
            error => {
                console.log(error)
                this.toastr.error('Problemas ao enviar SMS')
            },
        )
    }

    public handleNextStep() {
        if (this.isValidAuthCode()) {
            this.executeReset()
        } else {
            this.toastr.success('Informe um código de autenticação válido')
        }
    }
    public handleBackStep() {
        this.onBackStep.emit()
    }
}
