import { Component, Input } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AuthenticationService } from '../../services/authentication.service'
import { ErrorListArrayMessageParser } from '../../../core/services/error-list-array-message-parser.service'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
    selector: 'define-password-component',
    templateUrl: './account-user-define-password.component.html',
})
export class AccountUserDefinePasswordComponent {
    // Cross component
    @Input() definePasswordValidationToken!: any

    // Visualização
    confirmPasswordSetVisible = false
    redefinePasswordLoaderSetVisible = false
    showPassword = false
    showConfirmPassword = false
    showInvalidPasswordSchema = false
    timeToWaitBeforeValidatePasswordSchema: any = null

    // Usuário
    accountUserEmail: any
    accountUserPassword: any
    accountUserConfirmPassword: any

    // Tipo de ajuste
    updateType: any

    constructor(
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private errorListMessageParser: ErrorListArrayMessageParser,
    ) {}

    ngOnInit() {
        this.getDefinePasswordValidationTokenContent()
    }

    getDefinePasswordValidationTokenContent() {
        this.authenticationService
            .getDefinePasswordValidationTokenContent(this.definePasswordValidationToken)
            .subscribe(
                data => {
                    // @ts-ignore
                    if (data.value) {
                        // @ts-ignore
                        this.updateType = data.type
                        // @ts-ignore
                        this.accountUserEmail = data.value.email
                    }
                },
                error => {
                    console.log(error)
                    window.location.href = '/'
                },
            )
    }
    validateCreateAccountUserAfterPreRegisterPassword(keyPressed: any) {
        switch (keyPressed.keyCode) {
            case 32:
                return
            case 13:
                return
            case 8:
                if (this.accountUserPassword == undefined) return
        }

        clearTimeout(this.timeToWaitBeforeValidatePasswordSchema)
        this.timeToWaitBeforeValidatePasswordSchema = setTimeout(() => {
            let regex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*?[@$!%*?&#._-])[A-Za-z\d@$!%*?&#._-]{8,}/
            if (!this.accountUserPassword.match(regex)) this.showInvalidPasswordSchema = true
            else this.showInvalidPasswordSchema = false
        }, 1000)
    }

    firePasswordUpdate() {
        if (
            !this.accountUserPassword ||
            this.accountUserPassword !== this.accountUserConfirmPassword ||
            this.showInvalidPasswordSchema
        )
            return
        this.redefinePasswordLoaderSetVisible = true

        if (this.updateType == 'reset_password') this.resetAccountUserPassword()
        else this.createAccountUserAfterPreRegister()
    }

    createAccountUserAfterPreRegister() {
        let objectToSend = {
            account_user_email: this.accountUserEmail,
            account_user_password: this.accountUserPassword,
            account_user_pre_register_validation_token: this.definePasswordValidationToken,
        }
        this.authenticationService.createAccountUserAfterPreRegister(objectToSend).subscribe(
            data => {
                if (data.success) {
                    this.toastr.success('Senha definida com sucesso')

                    setTimeout(() => {
                        window.location.href = '/'
                    }, 2000)
                } else {
                    console.log(data)
                    this.errorListMessageParser.showErrorListMessage(data.errorList)
                    data.errorList.map((error: any) => {
                        if (error.internal_errors_list) {
                            const internalError = error.internal_errors_list
                            let outputErrorMessage =
                                'Não foi possível atualizar senha do usuário. Consulte os logs para mais informações'

                            if (internalError['bodyrequest.password'])
                                outputErrorMessage = internalError['bodyrequest.password'][0]
                            if (internalError.notifications)
                                outputErrorMessage = internalError.notifications.Password[0]

                            this.toastr.error(outputErrorMessage)
                        }
                    })

                    this.redefinePasswordLoaderSetVisible = false
                }
            },
            (response: HttpErrorResponse) => {
                this.errorListMessageParser.showErrorListMessageOrDefault(
                    response.error?.errorList,
                    'Falha não mapeada',
                )
                this.redefinePasswordLoaderSetVisible = false
            },
        )
    }

    resetAccountUserPassword() {
        let objectToSend = {
            password: this.accountUserPassword,
            token: this.definePasswordValidationToken,
        }
        this.authenticationService.resetAccountUserPassword(objectToSend).subscribe(
            data => {
                this.toastr.success('Senha atualizada com sucesso')
                setTimeout(() => {
                    this.redefinePasswordLoaderSetVisible = false
                    window.location.href = '/'
                }, 2000)
            },
            response => {
                let outputErrorMessage =
                    'Não foi possível atualizar senha do usuário. Consulte os logs para mais informações'
                if (response?.error['bodyrequest.password'])
                    outputErrorMessage = response?.error['bodyrequest.password'][0]
                if (response?.error?.notifications)
                    outputErrorMessage = response?.error?.notifications.Password[0]

                this.toastr.error(outputErrorMessage)

                this.redefinePasswordLoaderSetVisible = false
            },
        )
    }
}
