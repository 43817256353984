import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { ManageLoggedUser } from '../../../legacy/authentication/services/manage-logged-user.service'
import { AccessService } from '../../../legacy/core/services/access-service.service'

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard implements CanActivate {
    constructor(
        private loggedUserService: ManageLoggedUser,
        private accessService: AccessService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.loggedUserService.isLoggedIn()) {
            const hasPermission = this.accessService.hasRoles(['access-config-profile'])

            if (!hasPermission) {
                this.router.navigate(['/not-authorized'])
            }

            return hasPermission
        }

        this.router.navigate(['/'])
        return false
    }
}
