@if (profile$ | async; as profile) {
    <form class="section" [formGroup]="updateProfileForm">
        <h2 class="emd-text _size_page _color_default _type_bold _sub_title">Suas Informações</h2>
        <div class="container emd-container _footer_end _rounded">
            <div class="container emd-container">
                <div class="emd-container__header">
                    <h2 class="emd-text _size_section _color_default _type_bold">Dados do Usuário</h2>
                </div>

                <div class="emd-container__content _form_section">
                    <div class="_input">
                        <label class="emd-label" for="name">Nome Completo</label>
                        <input class="emd-input" disabled [value]="profile.user.name" />
                    </div>
                    <div class="_input">
                        <label class="emd-label" for="document_number">CPF</label>
                        <input class="emd-input" type="text" formControlName="document_number" placeholder="___.___.___-__" [mask]="cpf" />
                    </div>
                    @if (profile.isOwner) {
                        <div class="_input">
                            <label class="emd-label" for="birth_date">Data de Nascimento</label>
                            <input
                                type="date"
                                name="birth_date"
                                id="birth_date"
                                formControlName="birth_date"
                                class="emd-input emd-datepicker datepicker-input"
                                [value]="profile.user.birth_date ? (profile.user.birth_date | date: 'dd/MM/yyyy') : ''"
                            />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="gender">Gênero</label>
                            <div class="emd-select">
                                <select name="gender" formControlName="gender" id="gender" required>
                                    @for (item of genders; track $index) {
                                        <option [value]="item.id">
                                            {{ item.title }}
                                        </option>
                                    }
                                </select>
                                <div class="emd-select__arrow"></div>
                            </div>
                        </div>
                    }
                    <div class="_input">
                        <label class="emd-label" for="phone">Telefone</label>
                        <input
                            class="emd-input"
                            mask="(00) 0 0000-0000"
                            [value]="profile.user.phone_number | mask: '(00) 0 0000-0000'"
                            formControlName="phone_number"
                        />
                    </div>
                    <div class="_input">
                        <label class="emd-label" for="email">Email</label>
                        <input class="emd-input" disabled [value]="profile.user.email" />
                    </div>
                    <div class="_input">
                        <label class="emd-label" for="user_type_alias">Tipo de Usuário</label>
                        <input class="emd-input" disabled [value]="profile.user.user_type_alias" />
                    </div>
                </div>
            </div>

            @if (profile.isOwner) {
                <div class="container emd-container">
                    <div class="emd-container__header">
                        <h2 class="emd-text _size_section _color_default _type_bold">Dados da Empresa</h2>
                    </div>
                    <div class="emd-container__content _form_section">
                        <div class="_input">
                            <label class="emd-label" for="name">Nome</label>
                            <input class="emd-input" disabled [value]="profile.partner.name" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="document_number">CNPJ</label>
                            <input class="emd-input" disabled [value]="profile.partner.document_number | mask: '00.000.000/0000-00'" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="type_of_business">Segmento</label>
                            <input class="emd-input" type="text" disabled [value]="profile.partner.business.segment ?? ''" />
                        </div>
                        <div class="_input">
                            <div class="_label">
                                <label class="emd-label" for="client_base">Base de Clientes</label>
                                <span
                                    class="emd-tooltip _position_right"
                                    data-tooltip="A quantidade de clientes que você atende com a sua solução"
                                >
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </span>
                            </div>

                            <div class="emd-select">
                                <select name="client_base" formControlName="client_base" [value]="profile.partner.business.client_base">
                                    <option [value]="null" default>Selecione</option>
                                    @for (base of client_base_list; track $index) {
                                        <option [value]="base">
                                            {{ base }}
                                        </option>
                                    }
                                </select>
                                <div class="emd-select__arrow"></div>
                            </div>
                        </div>
                        <div class="_input">
                            <div class="_label">
                                <label class="emd-label" for="monthly_billing">Faturamento Mensal Médio por Cliente</label>
                                <span class="emd-tooltip _position_right" data-tooltip="O volume que seus clientes faturam mensalmente">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </span>
                            </div>

                            <div class="emd-select">
                                <select
                                    name="monthly_billing"
                                    formControlName="monthly_billing"
                                    [value]="profile.partner.business.monthly_billing"
                                >
                                    <option [value]="null" default>Selecione</option>
                                    @for (invoice of invoicing_options; track $index) {
                                        <option [value]="invoice">
                                            {{ invoice }}
                                        </option>
                                    }
                                </select>
                                <div class="emd-select__arrow"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container emd-container">
                    <div class="emd-container__header">
                        <h2 class="emd-text _size_section _color_default _type_bold">Endereço</h2>
                    </div>
                    <div class="emd-container__content _form_section">
                        <div class="_input">
                            <label class="emd-label" for="cep">CEP</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.cep" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="state">Estado</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.state" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="city">Cidade</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.city" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="neighborhood">Bairro</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.neighborhood" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="address">Logradouro</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.address" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="number">Número</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.number" />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="complement">Complemento</label>
                            <input class="emd-input" disabled [value]="profile.partner.address?.complement" />
                        </div>
                    </div>
                </div>
            }

            <div class="emd-container__footer">
                @if (updateProfileForm.disabled) {
                    <button class="emd-btn _color_primary" (click)="changeEditForm()">Editar</button>
                } @else {
                    <button class="emd-btn _color_primary" (click)="onSubmit()" [disabled]="isUpdatingProfile">
                        @if (isUpdatingProfile) {
                            <app-dot-loading [isLoading]="isUpdatingProfile" [color]="'var(--color-subtle)'"></app-dot-loading>
                        } @else {
                            <span class="emd-text"> Salvar </span>
                        }
                    </button>
                }
            </div>
        </div>
    </form>

    @if (profile.isOwner) {
        <div class="section">
            <h2 class="emd-text _size_page _color_default _type_bold _sub_title">Informações da Parceria e Plataforma</h2>
            <div class="container emd-container _footer_end _rounded">
                <div class="container emd-container">
                    <div class="emd-container__header">
                        <h2 class="emd-text _size_section _color_default _type_bold">Termos de Adesão e Uso</h2>
                    </div>
                    <div class="emd-container__content _form_section">
                        <div class="_input">
                            <label class="emd-label" for="acceptance_responsible_name">Responsável pelo Aceite</label>
                            <input
                                class="emd-input"
                                disabled
                                [value]="profile.partner.program.terms_and_conditions?.acceptance_responsible_name"
                            />
                        </div>
                        <div class="_input">
                            <label class="emd-label" for="acceptance_date">Data de Aceite</label>
                            <input class="emd-input" disabled [value]="profile.partner.program.terms_and_conditions?.acceptance_date" />
                        </div>
                        @if (profile.partner.program.terms_and_conditions) {
                            <button
                                class="emd-btn _color_primary _request_terms"
                                [disabled]="isRequestingTerms"
                                (click)="requestTermsAndConditions()"
                            >
                                @if (isRequestingTerms) {
                                    <app-dot-loading [isLoading]="isRequestingTerms" [color]="'var(--color-subtle)'"></app-dot-loading>
                                } @else {
                                    <span class="emd-text">
                                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                        Solicitar Termos
                                    </span>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
} @else {
    <div class="_loading">
        <div class="spinner-border"></div>
        <p class="emd-text _size_section _type_bold _color_subtle">Carregando...</p>
    </div>
}
