<div class="emd-container step-container">
    <div class="emd-container__header eu-justify_center">
        <h1 class="emd-text step-title">Digite Seu Código de Autenticação</h1>
    </div>
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="el-chunk eu-display_flex eu-justify_center">
            <img src="assets/google-auth-codes.svg" alt="Imagem do google authenticator" />
        </div>
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <div class="el-chunk">
                <p class="emd-text">
                    Digite o código de 6 dígitos que aparece no seu aplicativo de autenticação
                </p>
            </div>
        </div>
        <div class="el-chunk warning-card">
            <div
                class="emd-tag _color_warning w-100 eu-display_flex eu-align-items_center eu-justify_center eu-pa_2"
                style="border-radius: 24px"
            >
                <div><img src="/assets/icons/warning-icon.svg" alt="Ícone de aviso" /></div>
                <span class="emd-text _size_small eu-ml_2"
                    >Caso já tenha cadastro na Pagar.me, o código de autenticação é o mesmo
                    utilizado no login</span
                >
            </div>
        </div>
        <div class="el-chunk">
            <label class="emd-label">Código de Autenticação</label>
            <input
                [ngClass]="{ _state_error: hasEnteredInvalidCode }"
                class="emd-input"
                placeholder="Digite o código de 6 dígitos que aparece no seu aplicativo de autenticação"
                [mask]="authCodeMask"
                [(ngModel)]="twoFactorCode"
                (keypress)="inputEnterKeyAction($event)"
                #input2FACode
            />
            <div
                class="eu-display_flex eu-align-items_center eu-mt_2 eu-px_4 eu-py_2"
                style="gap: 0.5rem; border-radius: 24px; background-color: #ffe0e0"
                *ngIf="hasEnteredInvalidCode"
            >
                <i
                    class="fa fa-times-circle"
                    style="color: var(--color-danger)"
                    aria-hidden="true"
                ></i>
                <p class="emd-input-feedback _state_error">
                    Não conseguimos identificar o código de autenticação. Verifique e tente
                    novamente.
                </p>
            </div>
        </div>
        <div class="el-chunk" (click)="handleLoginProblems()">
            <span class="emd-text _color_primary eu-cursor_pointer">Problemas com o login?</span>
        </div>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button
                class="emd-btn _color_primary _type_text"
                [disabled]="signInLoaderIsVisible"
                (click)="handleBackToLogin()"
            >
                Voltar
            </button>
            <button
                class="emd-btn _color_primary"
                [disabled]="!isValidAuthCode() || signInLoaderIsVisible"
                (click)="handleAuthorizeAccountUser()"
            >
                <span *ngIf="!signInLoaderIsVisible"> Entrar</span>
                <app-dot-loading
                    *ngIf="signInLoaderIsVisible"
                    [isLoading]="signInLoaderIsVisible"
                ></app-dot-loading>
            </button>
        </div>
    </div>
</div>
