import { Component, input, output } from '@angular/core'

@Component({
    selector: 'app-nav-mode-toggle',
    standalone: true,
    imports: [],
    templateUrl: './nav-mode-toggle.component.html',
    styleUrl: './nav-mode-toggle.component.scss',
})
export class NavModeToggleComponent {
    isSimpleNavigation = input.required<boolean>()
    onToggleNavMode = output<boolean>()

    toggleNaveMode(event: Event) {
        const button = event.target as HTMLButtonElement
        button.blur()
        this.onToggleNavMode.emit(this.isSimpleNavigation())
    }
}
