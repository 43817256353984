<div class="emd-container step-container">
    <div class="emd-container__content eu-display_flex eu-column">
        <div class="el-chunk">
            <p class="emd-text">
                Para aumentarmos a segurança da sua conta, é necessário que você ative a
                autenticação em duas etapas. Para isso, use um aplicativo autenticador para gerar
                seu código de confirmação.
            </p>
        </div>
        <div class="eu-display_flex eu-column" style="gap: 0.5rem">
            <h1 class="emd-text step-title _color_default eu-mb_2">
                <strong>Passo 1: baixar o app autenticador</strong>
            </h1>
            <div class="el-chunk">
                <p class="emd-text">
                    Entre no seu smartphone, vá até a Loja de Apps e baixe gratuitamente o
                    aplicativo de autenticação.
                </p>
                <p class="emd-text">O App recomendado é o <strong>Google Autenticador.</strong></p>
            </div>
        </div>
        <div class="el-chunk eu-display_flex eu-justify_space-between">
            <img
                src="assets/smartphone-stores.svg"
                size="118"
                alt="Imagem da google play e app store"
            />
            <img
                src="assets/google-authenticator.svg"
                size="140"
                alt="Imagem do google authenticator"
            />
        </div>
        <app-two-factor-faq></app-two-factor-faq>
    </div>
    <div class="emd-container__footer">
        <div class="eu-display_flex footer-gap w-100 eu-justify_end">
            <button class="emd-btn _color_primary _type_text" (click)="handleBackStep()">
                Voltar
            </button>
            <button class="emd-btn _color_primary" (click)="handleNextStep()">Continuar</button>
        </div>
    </div>
</div>
