import { Component, OnInit } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AccountUsersModule } from '../../../../legacy/features/account-users/account-users.module'

import { CommercialPartner } from '../../../../core/types/entities/partner/commercial-partner'
import { ManageLoggedUser } from '../../../../legacy/authentication/services/manage-logged-user.service'
import { PartnersDataService } from '../../../../shared/services/partners-data.service'

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [AccountUsersModule],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit {
    isInternalUser = false
    isPartnerDataLoaded = false

    partnerData!: CommercialPartner

    constructor(
        private toastr: ToastrService,
        private loggedUserService: ManageLoggedUser,
        private partnersDataService: PartnersDataService,
    ) {}

    ngOnInit() {
        this.isInternalUser = this.loggedUserService.getLoggedAccountUser().loggedUser.hub_partner_id === 'none'
        if (!this.isInternalUser) this.getCommercialPartnersData()
    }

    getCommercialPartnersData() {
        const filterObject = {
            page_number: 1,
            registers_per_page: 1,
        }
        this.partnersDataService.getCommercialPartnersData(filterObject).subscribe({
            next: data => {
                if (data.success) {
                    this.partnerData = data.commercial_partners_data[0]
                    this.isPartnerDataLoaded = true
                } else {
                    if (data.errorList[0]) {
                        this.toastr.warning(data.errorList[0].message, 'Atenção')
                        console.log(data.errorList)
                    }
                }
            },
            error: error => {
                this.toastr.error('Possível problema de conexão localizado', 'Falha')
                console.log(error)
            },
        })
    }
}
