<div>
    <div class="col col-md-4 offset-md-4 text-center">
        <h3><strong>Dados do usuário</strong></h3>
    </div>
    <div class="col col-md-4 offset-md-4">
        <div class="emd-form-group">
            <input class="emd-input" placeholder="Nome do usuário" readonly [(ngModel)] = 'userName'>
        </div>
        <div class="emd-form-group">
            <input class="emd-input" placeholder="E-mail" readonly [(ngModel)] = 'userEmail'>
        </div>
        <div class="emd-form-group" *ngIf = 'userPartnerAssociated'>
            <input class="emd-input" placeholder="Parceiro" readonly [(ngModel)] = 'userPartnerAssociated'>
        </div>
        <div class="emd-form-group">
            <input class="emd-input" placeholder="Tipo de usuário" readonly [(ngModel)] = 'userAccessType'>
        </div>
        <div class="emd-form-group">
            <button class="emd-btn _color_danger _type_outline _block" (click)='confirmLogout()'>Sair do Partner Hub :(</button>
        </div>
    </div>
</div>
