<div [ngSwitch]="currentStep">
    <app-login-problems-info
        *ngSwitchCase="recoveryAccountSteps.STEP_1"
        (onHandleNextStep)="handleNextStep()"
        (onRetryTypeAuthCode)="handleRetryTypeAuthCode()"
    ></app-login-problems-info>
    <app-recovery-account
        *ngSwitchCase="recoveryAccountSteps.STEP_2"
        [authCode]="authCode"
        [accountUserEmail]="accountUserEmail"
        (onBack)="handleBackStep()"
        (onCodelessRecovery)="handleCodelessRecovery()"
        (onSuccessRecoveryAccount)="handleNextStep()"
    ></app-recovery-account>
    <app-recovery-account-success
        *ngSwitchCase="recoveryAccountSteps.STEP_3"
        (onSuccessRecoveryAccount)="handleSuccessRecoveryAccount()"
    ></app-recovery-account-success>
    <app-codeless-access-recovery
        *ngSwitchCase="recoveryAccountSteps.CODELESS_RECOVERY"
        (onRetryTypeAuthCode)="handleRetryTypeAuthCode()"
    >
    </app-codeless-access-recovery>
</div>
