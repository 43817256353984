import {
    booleanAttribute,
    Component,
    computed,
    effect,
    inject,
    input,
    OnDestroy,
    OnInit,
    output,
    signal,
} from '@angular/core'
import { NavigationNode } from '../../types/navigation'
import { SvgIconComponent } from 'angular-svg-icon'
import { NodeIconComponent } from '../node-icon/node-icon.component'
import { NavToggleComponent } from '../nav-toggle/nav-toggle.component'
import { NavigationBarService } from '../../services/navigation-bar.service'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'app-navigation-node',
    standalone: true,
    imports: [SvgIconComponent, NodeIconComponent, NavToggleComponent],
    templateUrl: './navigation-node.component.html',
    styleUrl: './navigation-node.component.scss',
})
export class NavigationNodeComponent implements OnInit, OnDestroy {
    navBarService = inject(NavigationBarService)

    node = input.required<NavigationNode>()
    isExpanded = input<boolean>(false)
    isRoot = input<boolean>(true)

    onHandleNavigation = output<NavigationNode>()
    onNodeClick = output<NavigationNode>()

    isActiveRoot = signal(false)
    isActiveChild = signal(false)
    hasChildrenNodes = computed<boolean>(() => this.node().nodes.length > 0)
    hasDropDownNavigationMenu = computed<boolean>(
        () => this.hasChildrenNodes() && !this.isExpanded(),
    )
    isHovered = signal(false)

    showNodChildren = signal(false)
    isSimpleNavigation = signal(false)

    private _onDestroy$ = new Subject()

    ngOnInit(): void {
        this.initializeSubscriptions()
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }

    private initializeSubscriptions() {
        this.observeNavBarNavigationMode()
        this.observeWhenSimplifiedNavigationFocusedNode()
        this.observeNodeClicks()
        this.observeActiveRootNode()
        this.observeActiveChildrens()
    }

    private observeNodeClicks() {
        this.navBarService.onNodeClick
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((node: NavigationNode) => {
                if (node) {
                    this.processNodeClick(node)
                }
            })
    }

    private observeNavBarNavigationMode() {
        this.navBarService.isSimplifiedNavigation
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(isSimplified => {
                if (isSimplified) {
                    this.closeNodeChildren()
                } else {
                    this.isSimpleNavigation.set(false)
                }
            })
    }

    private observeWhenSimplifiedNavigationFocusedNode() {
        this.navBarService.focusedRootNode.pipe(takeUntil(this._onDestroy$)).subscribe(node => {
            if (node && node.label === this.node().label) {
                if (this.hasDropDownNavigationMenu()) {
                    this.isSimpleNavigation.set(true)
                }
            } else {
                this.isSimpleNavigation.set(false)
            }
        })
    }

    private observeActiveRootNode() {
        this.navBarService.activeNode.pipe(takeUntil(this._onDestroy$)).subscribe(activeNode => {
            if (activeNode && this.node().label === activeNode.label) {
                this.isActiveRoot.set(true)
            } else {
                this.isActiveRoot.set(false)
            }
        })
    }

    private observeActiveChildrens() {
        this.navBarService.activeNodeChildren.pipe(takeUntil(this._onDestroy$)).subscribe(nodes => {
            if (!this.isRoot() && nodes.some(node => this.node().label === node.label)) {
                this.isActiveChild.set(true)
            } else {
                this.isActiveChild.set(false)
            }
        })
    }

    handleNavigate(node: NavigationNode) {
        this.onHandleNavigation.emit(node)
    }

    handleNodeClick(node: NavigationNode) {
        this.navBarService.dispatchNodeClick(node)
    }

    processNodeClick(node: NavigationNode) {
        const currentNode = this.node()

        if (node.label === currentNode.label) {
            if (this.hasChildrenNodes()) {
                this.showNodChildren.update(isShowingChildren => !isShowingChildren)
            } else {
                this.handleNavigate(currentNode)
            }
            return
        }

        const isSameParant = node.parent === currentNode.parent
        const isMySon = node.parent === currentNode.label

        if (isSameParant && !isMySon) {
            this.showNodChildren.set(false)
        }
    }

    closeNodeChildren() {
        this.showNodChildren.set(false)
    }

    handleDropwDownMenuFocus() {
        if (!this.isExpanded()) {
            this.navBarService.stopFocusedNodeTimeout()
        }
    }

    toggleHover() {
        this.isHovered.update(isHovered => !isHovered)
    }
}
