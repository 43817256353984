import { AsyncPipe, DatePipe } from '@angular/common'
import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { ToastrService } from 'ngx-toastr'
import { map, Observable, Subject, takeUntil } from 'rxjs'
import { SharedModule } from '../../../../legacy/shared/shared.module'
import { client_base_options, invoicing_options } from '../../../../shared/static'
import { ProfileService } from '../../services/profile.service'
import { Profile } from '../../types/profile.types'

interface UpdateProfileForm {
    phone_number: FormControl<string>
    client_base?: FormControl<string>
    monthly_billing?: FormControl<string>
    document_number: FormControl<string>
    birth_date?: FormControl<string>
    gender?: FormControl<string>
}

@Component({
    selector: 'app-profile',
    standalone: true,
    imports: [AsyncPipe, NgxMaskDirective, ReactiveFormsModule, NgxMaskPipe, DatePipe, SharedModule],
    providers: [provideNgxMask()],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit, OnDestroy {
    private profileService = inject(ProfileService)
    private formBuilder = inject(NonNullableFormBuilder)
    private toastr = inject(ToastrService)

    client_base_list = client_base_options
    invoicing_options = invoicing_options

    protected isUpdatingProfile = false
    protected isRequestingTerms = false

    protected profile$!: Observable<Profile>
    protected isDisabled = true
    private _onDestroy$ = new Subject()
    protected genders = [
        { id: 'male', title: 'Masculino' },
        { id: 'female', title: 'Feminino' },
        { id: 'other', title: 'Prefiro Não Declarar' },
    ]

    protected updateProfileForm = this.formBuilder.group<UpdateProfileForm>({
        phone_number: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
        document_number: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
        gender: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
        birth_date: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
    })

    private profile?: Profile | null
    public cpf = '000.000.000-00'

    ngOnInit() {
        this.profile$ = this.profileService.profile
        this.subscribeForm()
        this.profileService.getProfileData()
    }

    private subscribeForm() {
        this.profile$
            .pipe(
                takeUntil(this._onDestroy$),
                map(profile => {
                    this.profile = profile
                    this.setupFormValues()
                    if (profile.isOwner) {
                        this.addOwnerControls()
                    }
                }),
            )
            .subscribe()
    }

    protected setupFormValues() {
        if (this.profile?.user.phone_number) {
            this.updateProfileForm.controls.phone_number.setValue(this.profile.user.phone_number)
        }

        if (this.profile?.user.document_number) {
            this.updateProfileForm.controls.document_number.setValue(this.profile.user.document_number)
        }

        if (this.profile?.user.gender) {
            const genderValue = this.profile.user.gender === 'uninformed' ? 'other' : this.profile.user.gender
            this.updateProfileForm.controls.gender?.setValue(genderValue)
        }

        if (this.profile?.isOwner && this.profile?.user.birth_date) {
            this.updateProfileForm.controls.birth_date?.setValue(this.profile.user.birth_date)
        }
    }

    protected addOwnerControls() {
        this.updateProfileForm.addControl(
            'client_base',
            new FormControl(
                { value: this.profile?.partner?.business.client_base ?? '', disabled: true },
                {
                    nonNullable: true,
                    validators: [Validators.required],
                },
            ),
        )
        this.updateProfileForm.addControl(
            'monthly_billing',
            new FormControl(
                { value: this.profile?.partner?.business.monthly_billing ?? '', disabled: true },
                {
                    nonNullable: true,
                    validators: [Validators.required],
                },
            ),
        )
        this.updateProfileForm.addControl(
            'gender',
            new FormControl(
                { value: this.profile?.user?.gender ?? '', disabled: true },
                {
                    nonNullable: true,
                    validators: [Validators.required],
                },
            ),
        )
        this.updateProfileForm.addControl(
            'birth_date',
            new FormControl(
                { value: this.profile?.user?.birth_date ?? '', disabled: true },
                {
                    nonNullable: true,
                    validators: [Validators.required],
                },
            ),
        )
    }

    protected changeEditForm() {
        if (this.isDisabled) {
            if (this.profile?.isOwner) {
                if (!this.profile?.user.gender) {
                    this.updateProfileForm.controls['gender']?.enable()
                }
                if (!this.profile?.user.birth_date) {
                    this.updateProfileForm.controls['birth_date']?.enable()
                }
            }

            if (!this.profile?.user.document_number) {
                this.updateProfileForm.controls['document_number'].enable()
            }

            this.updateProfileForm.controls['phone_number']?.enable()
        } else {
            this.updateProfileForm.disable()
            this.isUpdatingProfile = false
        }
        this.isDisabled = !this.isDisabled
    }

    protected onSubmit() {
        this.isUpdatingProfile = true
        const result = this.updateProfileForm.value
        this.profileService.updateProfile(result, () => this.changeEditForm())
    }

    getGender(gender?: string) {
        if (gender == 'male') return 'Masculino'
        if (gender == 'female') return 'Feminino'

        return 'Prefiro Não Declarar'
    }

    requestTermsAndConditions() {
        this.isRequestingTerms = true

        this.profileService.requestTermsAndConditions(() => {
            this.isRequestingTerms = false
        })
    }

    validateYear(event: Event): void {
        const input = event.target as HTMLInputElement
        const date = input.value

        if (date) {
            const year = date.split('-')[0]
            if (year && year.length > 4) {
                input.value = date.slice(0, 4) + date.slice(5)
            }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy$.next({})
    }
}
