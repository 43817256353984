import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core'
import { provideRouter, withHashLocation } from '@angular/router'

import { registerLocaleData } from '@angular/common'
import { provideHttpClient } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideAngularSvgIcon } from 'angular-svg-icon'
import { provideNgxMask } from 'ngx-mask'
import { provideToastr } from 'ngx-toastr'
import { routes } from './app.routes'

registerLocaleData(localePt)

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withHashLocation()),
        provideToastr({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true,
        }),
        provideNgxMask(),
        provideHttpClient(),
        provideAnimations(),
        provideAngularSvgIcon(),
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
}
