import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-recovery-account-success-modal',
    templateUrl: './recovery-account-success-modal.component.html',
    styleUrls: ['./recovery-account-success-modal.component.scss'],
})
export class RecoveryAccountSuccessModalComponent implements OnInit {
    constructor(private activeModalService: NgbActiveModal) {}

    ngOnInit(): void {}

    handleConfigure2fa() {
        this.activeModalService.close()
    }
}
