@if (isLoading()) {
    <div class="eu-flex loader-position mt-5">
        <div class="eu-basis_12 eu-basis_10_md eu-offset_1_md">
            <div class="eu-basis_12 eu-basis_2_md eu-offset_5_md emd-text _align_center _type_hint _color_primary">
                <div class="spinner-border"></div>
                <h2 class="emd-text _size_page">Carregando</h2>
            </div>
        </div>
    </div>
} @else {
    @if (publicRoute()) {
        <router-outlet></router-outlet>
    } @else {
        <ng-container class="teste" *ngIf="currentUser">
            <app-base-layout *ngIf="rolesService.loaded && modulesLoaded">
                <router-outlet></router-outlet>
            </app-base-layout>
        </ng-container>

        <authentication-component
            *ngIf="!currentUser && !definePasswordSetVisible"
            (singInAccountUser)="singInAccountUser($event)"
        ></authentication-component>

        <define-password-component
            *ngIf="definePasswordSetVisible"
            [definePasswordValidationToken]="definePasswordValidationToken"
        ></define-password-component>
    }
}
