import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-two-factor-login',
    templateUrl: './two-factor-login.component.html',
    styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent implements OnInit, OnChanges, AfterContentInit {
    authCodeMask = '000 000'

    @Input() twoFactorCode!: string
    @Input() hasEnteredInvalidCode: boolean = false
    @Input() signInLoaderIsVisible: boolean = false
    @Output() onAuthorizeAccountUser = new EventEmitter<string>()
    @Output() onBackToLoginFlow = new EventEmitter()
    @Output() onHandleLoginProblems = new EventEmitter()
    @ViewChild('input2FACode') input2FACode!: ElementRef<HTMLInputElement>

    constructor(private toastr: ToastrService) {}

    ngOnInit(): void {}

    ngAfterContentInit(): void {
        setTimeout(() => this.input2FACode.nativeElement.focus(), 500)
    }

    ngOnChanges(changes: SimpleChanges) {}

    unmask2faCode() {
        return this.twoFactorCode.replace(/\D+/g, '')
    }

    public isValidAuthCode() {
        if (this.twoFactorCode) {
            const formattedCode = this.unmask2faCode()
            return formattedCode.toString().length === 6
        }

        return false
    }

    public handleBackToLogin() {
        this.onBackToLoginFlow.emit()
    }

    public handleAuthorizeAccountUser() {
        if (this.isValidAuthCode()) {
            this.onAuthorizeAccountUser.emit(this.unmask2faCode())
        } else {
            this.toastr.error('Informe um código válido')
        }
    }

    public handleLoginProblems() {
        this.onHandleLoginProblems.emit()
    }

    inputEnterKeyAction(keyPressed: any) {
        if (keyPressed.keyCode !== 13) return

        if (!this.isValidAuthCode()) return

        this.handleAuthorizeAccountUser()
    }
}
