@if (canShowSideBar()) {
    <app-navigation-bar class="emd-container" />
}
<div
    class="main-content"
    [class.simple-nave-mode]="isSimpleNavigation()"
    [class.expanded-nav-mode]="!isSimpleNavigation()"
>
    <ng-content></ng-content>
</div>
