@if (isExpanded()) {
    <div class="theme-tab">
        <div class="theme-option" [class.active-option]="!isDark()" (click)="switchTheme()">
            <svg-icon
                class="dark-theme-icon theme-icon"
                [class.active]="!isDark()"
                src="assets/icons/navigation/light-theme.svg"
                [applyClass]="true"
            />
        </div>

        <div class="theme-option" [class.active-option]="isDark()" (click)="switchTheme()">
            <svg-icon
                class="light-theme-icon theme-icon"
                [class.active]="isDark()"
                src="assets/icons/navigation/dark-theme.svg"
                [applyClass]="true"
            />
        </div>
    </div>
} @else {
    <div class="simple-theme-tab" (click)="switchTheme()">
        <svg-icon
            class="theme-icon"
            [src]="
                isDark()
                    ? 'assets/icons/navigation/dark-theme.svg'
                    : 'assets/icons/navigation/light-theme.svg'
            "
            [applyClass]="true"
        />
    </div>
}
