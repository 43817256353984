import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-two-factor-force-sms-reset-page',
    templateUrl: './two-factor-force-sms-reset-page.component.html',
})
export class TwoFactorForceSmsResetPageComponent implements OnInit {
    @Output() onNextStep = new EventEmitter()
    @Output() onResetLogin = new EventEmitter()
    @Input() authCode!: string

    startReset: boolean = false

    constructor() {}

    ngOnInit(): void {}

    public restartResetProcess() {
        this.startReset = false
    }

    public handleBackStep() {
        this.onResetLogin.emit()
    }

    public handleNextStep() {
        this.startReset = true
    }
}
